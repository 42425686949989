import React from "react";
import { Helmet } from "react-helmet";
import { Switch, Route } from "react-router-dom";
// Screens
import Landing from "./screens/Landing.jsx";
import Partners from "./screens/Partners.jsx";
import AboutUsPage from "./screens/AboutUsPage.jsx";
import SIATraining from "./screens/SIATraining.jsx";
import SecurityServices from "./screens/SecurityServices.jsx";
import CleaningServices from "./screens/CleaningServices.jsx";
import FireMarshal from "./screens/FireMarshal.jsx";
import PrivacyPolicy from "./screens/PrivacyPolicy.jsx";
import Tnc from "./screens/Tnc.jsx";
import Disclaimer from "./screens/Disclaimer.jsx";
import Ss from "./screens/Ss.jsx";
import Internships from "./screens/Internships.jsx";
import Housing from "./screens/Housing.jsx";

export default function App() {
  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Khula:wght@400;600;800&display=swap" rel="stylesheet" />
      </Helmet>


      <Switch>
        <Route exact path="/">
          <Landing />
        </Route>
        <Route path="/partners">
          <Partners />
        </Route>
        <Route path="/about">
          <AboutUsPage/>
        </Route>
        <Route path="/sia-training">
          <SIATraining/>
        </Route>
        <Route path="/security-services">
          <SecurityServices/>
        </Route>
        <Route path="/cleaning-services">
          <CleaningServices/>
        </Route>
        <Route path="/firemarshal">
          <FireMarshal/>
        </Route>
        <Route path="/privacy-policy">
          <PrivacyPolicy/>
        </Route>
        <Route path="/terms-and-conditions">
          <Tnc/>
        </Route>
        <Route path="/disclaimer">
          <Disclaimer/>
        </Route>
        <Route path="/security-services-main">
          <Ss/>
        </Route>
        <Route path ="/internships">
          <Internships/>
        </Route>
        <Route path="/housing-management">
           <Housing/>
        </Route>
      </Switch>
    </>
  );
}

