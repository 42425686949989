import {React, useState} from "react";
import styled from "styled-components";

export default function JoinUs(props) {
  const [formState, setFormState] = useState({});
  const changeHandler = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setFormState({...formState, "Service": props.service});
    if(formState.Name === "" || formState.Email === "" || formState.Message === "" || formState.Phone === "" ){
      alert("Please fill out all the fields");
      return;
    }
    //console.log(formState);
    const config = {
      SecureToken:"43bc1853-21ce-4a51-8b3d-57c57c8b1f14",
      To:"infobonkur@gmail.com",
      From:"infobonkur@gmail.com",
      Subject:`New Message from ${formState.Name}`,
      Body:`<h2>You have a new message on ${props.service} page</h2>
      <h3>Name:</h3>
      <p>${formState.Name}</p>
      <h3>Phone Number:</h3>
        <p>${formState.Phone}</p>
      <h3>Email:</h3>
        <p>${formState.Email}</p>
      <h3>Service:</h3>
        <p>${props.service}</p>
      <h3>Message:</h3>
        <p>${formState.Message}</p>`,
    };
    if(window.Email){
      window.Email.send(config).then(
        alert("Message Sent Successfully")
      )
      .catch((err) => {
        console.log(err)
        alert("Message Failed to Send")
      });
    }
  };
  return (
    <div style={{
        border: "1px solid #14117a",
        padding: "20px",
        marginBottom: "20px",
        borderRadius: "30px",
        boxShadow: "0px 0px 7px 0px rgba(0,0,0,0.45)", 
    }}>
        <div className="row" style={{
                  marginTop: "20px",
              }}>
                  <div className="col-md-12 textCenter">
                  <h2>
                      <span className="extraBold font40">
                           Join Us
                      </span>
                  </h2>
                  <br/>
                  <p className="font14 semiBold flexCenter">
                      Please fill out the form below and one of our representatives will get in touch with you shortly.
                  </p>
                  </div>
              </div>
              <div className="row" style={{
                  marginBottom: "20px"
              }}>
                  <div className="col-md-12">
                    <Form>
                        <label className="font13">Name:</label>
                        <input type="text" id="fname" name="Name" className="font20 Bold" 
                          onChange={changeHandler}
                        />
                        <label className="font13">Email:</label>
                        <input type="text" id="email" name="Email" className="font20 Bold" onChange={changeHandler} required/>
                        <label className="font13">Phone:</label>
                        <input type="text" id="phone" name="Phone" className="font20 Bold" onChange={changeHandler}/>
                        <label className="font13">Service:</label>
                        <input type="text" id="service" name="Service" value={props.service} className="font18 Bold" disabled/>
                        <label className="font13">Message:</label>
                        <textarea rows="4" cols="50" type="text" id="message" name="Message" className="font20 Bold" onChange={changeHandler} />
                    </Form>
                    <SumbitWrapper className="flex">
                        <ButtonInput type="submit" value="Submit" className="pointer animate radius8" style={{ maxWidth: "220px" }} onClick={submitHandler}/>
                    </SumbitWrapper>
                  </div>
              </div>
    </div>
  );
}


const Wrapper = styled.section`
  padding-top: 80px;
  width: 100%;
  min-height: 840px;
  margin-top: 100px;
  @media (max-width: 960px) {
    flex-direction: column;
    margin-top: 10px;
  }
`;
const LeftSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    margin: 20px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 30px 0 50px 0;
  }
`;
const RightSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin-top: 30px;
  }
`;
const HeaderP = styled.div`
  max-width: 470px;
  padding: 15px 0 50px 0;
  line-height: 1.5rem;
  @media (max-width: 960px) {
    padding: 15px 0 50px 0;
    text-align: center;
    max-width: 100%;
  }
`;
const BtnWrapper = styled.div`
  max-width: 190px;
  @media (max-width: 960px) {
    margin: 0 auto;
  }
`;
const GreyDiv = styled.div`
  width: 30%;
  height: 430px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  @media (max-width: 960px) {
    display: none;
  }
`;
const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 9;
  @media (max-width: 960px) {
    width: 100%;
    justify-content: center;
  }
`;
const Img = styled.img`
  @media (max-width: 960px) {
    width: 90%;
    height: auto;
    padding-bottom: 0;
  }
`;
const QuoteWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 50px;
  max-width: 330px;
  padding: 30px;
  z-index: 99;
  @media (max-width: 960px) {
    left: 20px;
  }
  @media (max-width: 560px) {
    bottom: -50px;
  }
`;
const QuotesWrapper = styled.div`
  position: absolute;
  left: -20px;
  top: -10px;
`;
const DotsWrapper = styled.div`
  position: absolute;
  right: -100px;
  bottom: 100px;
  z-index: 2;
  @media (max-width: 960px) {
    right: 100px;
  }
  @media (max-width: 560px) {
    display: none;
  }
`;
const Form = styled.form`
  padding: 70px 0 30px 0;
  input,
  textarea {
    width: 100%;
    background-color: transparent;
    border: 0px;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #707070;
    height: 30px;
    margin-bottom: 30px;
  }
  textarea {
    min-height: 100px;
  }
  @media (max-width: 860px) {
    padding: 30px 0;
  }
`;
const ButtonInput = styled.input`
  border: 1px solid #14117a;
  background-color: #14117a;
  width: 100%;
  padding: 15px;
  outline: none;
  color: #fff;
  :hover {
    background-color: #0d0b4e;
    border: 1px solid #7620ff;
    color: #fff;
  }
  @media (max-width: 991px) {
    margin: 0 auto;
  }
`;
const ContactImgBox = styled.div`
  max-width: 180px; 
  align-self: flex-end; 
  margin: 10px 30px 10px 0;
`;
const SumbitWrapper = styled.div`
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 50px;
  }
`;


