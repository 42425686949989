import React from "react";
import styled from "styled-components";
// Components
import TopNavbar from "../components/Nav/TopNavbar";
import Footer from "../components/Sections/Footer";
// Assets
import Img1 from "../assets/img/services/4.jpg";
import Img2 from "../assets/img/services/fm-1.jpg";
import Img3 from "../assets/img/services/fm-2.jpg";
import Dots from "../assets/svg/Dots";
import JoinUs from "../components/Sections/JoinUs";

export default function FireMarshal() {
  return (
    <>
      <TopNavbar />
      <Wrapper id="siatraining" className="container flexSpaceCenter">
        <div className="container ">
          <div className="row">
            <div className="col-md-6">
              <h1 className="extraBold font60">Fire Marshal</h1>
              <HeaderP className="font16 semiBold">
                Become a Fire Marshal and Make a Difference in Your Community!
                <br />
                <br />
                We are looking for dedicated individuals to join our team as
                Fire Marshals. As a Fire Marshal, you will play a critical role
                in ensuring the safety of your community by enforcing fire
                safety regulations and responding to fire emergencies.
              </HeaderP>
            </div>
            <div className="col-md-6 flexCenter">
              <ImageWrapper>
                <Img
                  className="radius8"
                  src={Img1}
                  alt="office"
                  style={{ zIndex: 9, paddingBottom: "65px" }}
                  width="100%"
                />
                <DotsWrapper>
                  <Dots />
                </DotsWrapper>
              </ImageWrapper>
              <GreyDiv className="lightBg"></GreyDiv>
            </div>
          </div>
          <div className="row ">
            <div className="col-md-6">
              <ImageWrapper>
                <Img
                  className="radius8"
                  src={Img2}
                  alt="office"
                  style={{ zIndex: 9, paddingBottom: "65px" }}
                  width="100%"
                />
              </ImageWrapper>
            </div>
            <div className="col-md-6">
              <HeaderP className="font16 semiBold">
                Why Become a Fire Marshal?
                <br />
                Make a difference: As a Fire Marshal, you will have the
                opportunity to make a positive impact on your community. You
                will help prevent fires by inspecting buildings and enforcing
                fire safety regulations. In the event of a fire emergency, you
                will be the first line of defense, responding to the scene to
                save lives and protect property.
                <br /> <br />
                Professional development: As a Fire Marshal, you will receive
                extensive training to help you develop the skills you need to
                succeed in this critical role. You will receive training on fire
                safety regulations, firefighting techniques, and emergency
                response protocols, among other things.
              </HeaderP>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <HeaderP className="font16 semiBold">
                Competitive salary and benefits: Fire Marshals typically earn
                competitive salaries and receive comprehensive benefits
                packages, which may include health insurance, retirement plans,
                and paid time off.
                <br />
                Join Our Fire Marshal Team Today!
                <br /> <br />
                If you're looking for a challenging and rewarding career that
                allows you to make a difference in your community, join our Fire
                Marshal team today! We are looking for dedicated individuals who
                are passionate about fire safety and committed to serving their
                communities. Contact us today to learn more about this exciting
                career opportunity and how you can become a Fire Marshal.
              </HeaderP>
            </div>
            <div className="col-md-6 flexCenter">
              <ImageWrapper>
                <Img
                  className="radius8"
                  src={Img3}
                  alt="office"
                  style={{ zIndex: 9, paddingBottom: "65px" }}
                  width="100%"
                />
              </ImageWrapper>
              <GreyDiv className="lightBg"></GreyDiv>
            </div>
          </div>
          {/* <JoinUs service="Fire Marshal" /> */}
        </div>
      </Wrapper>
      <Footer />
    </>
  );
}

const Wrapper = styled.section`
  padding-top: 80px;
  width: 100%;
  min-height: 840px;
  margin-top: 100px;
  @media (max-width: 960px) {
    flex-direction: column;
    margin-top: 10px;
  }
`;
const LeftSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    margin: 20px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 30px 0 50px 0;
  }
`;
const RightSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin-top: 30px;
  }
`;
const HeaderP = styled.div`
  max-width: 470px;
  padding: 15px 0 50px 0;
  line-height: 1.5rem;
  @media (max-width: 960px) {
    padding: 15px 0 50px 0;
    text-align: center;
    max-width: 100%;
  }
`;
const BtnWrapper = styled.div`
  max-width: 190px;
  @media (max-width: 960px) {
    margin: 0 auto;
  }
`;
const GreyDiv = styled.div`
  width: 30%;
  height: 430px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  @media (max-width: 960px) {
    display: none;
  }
`;
const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 9;
  @media (max-width: 960px) {
    width: 100%;
    justify-content: center;
  }
`;
const Img = styled.img`
  @media (max-width: 960px) {
    width: 90%;
    height: auto;
    padding-bottom: 0;
  }
`;
const QuoteWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 50px;
  max-width: 330px;
  padding: 30px;
  z-index: 99;
  @media (max-width: 960px) {
    left: 20px;
  }
  @media (max-width: 560px) {
    bottom: -50px;
  }
`;
const QuotesWrapper = styled.div`
  position: absolute;
  left: -20px;
  top: -10px;
`;
const DotsWrapper = styled.div`
  position: absolute;
  right: -100px;
  bottom: 100px;
  z-index: 2;
  @media (max-width: 960px) {
    right: 100px;
  }
  @media (max-width: 560px) {
    display: none;
  }
`;
const Form = styled.form`
  padding: 70px 0 30px 0;
  input,
  textarea {
    width: 100%;
    background-color: transparent;
    border: 0px;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #707070;
    height: 30px;
    margin-bottom: 30px;
  }
  textarea {
    min-height: 100px;
  }
  @media (max-width: 860px) {
    padding: 30px 0;
  }
`;
const ButtonInput = styled.input`
  border: 1px solid #14117a;
  background-color: #14117a;
  width: 100%;
  padding: 15px;
  outline: none;
  color: #fff;
  :hover {
    background-color: #0d0b4e;
    border: 1px solid #7620ff;
    color: #fff;
  }
  @media (max-width: 991px) {
    margin: 0 auto;
  }
`;
const ContactImgBox = styled.div`
  max-width: 180px; 
  align-self: flex-end; 
  margin: 10px 30px 10px 0;
`;
const SumbitWrapper = styled.div`
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 50px;
  }
`;
