import React from "react";
import styled from "styled-components";
// Components
import FullButton from "../components/Buttons/FullButton";
import TopNavbar from "../components/Nav/TopNavbar";
import Footer from "../components/Sections/Footer";
// Assets
import PartnerImg from "../assets/img/partner.jpg";
import PartnerImg1 from "../assets/img/partner-1.jpg";
import PartnerImg2 from "../assets/img/partner-2.jpg";
import p1 from "../assets/img/p-1.jpg";
import p2 from "../assets/img/p-2.jpg";
import p3 from "../assets/img/p-3.jpg";
import Dots from "../assets/svg/Dots";
import ClientSlider from "../components/Elements/ClientSlider";

export default function Partners() {
  return (
    <>
      <TopNavbar />
      <Wrapper id="partners" className="container flexSpaceCenter">
        <div className="container ">
          <div className="row">
            <div className="col-md-6">
              <h1 className="extraBold font60">Hello, Partners</h1>
              <HeaderP className="font16 semiBold">
                Welcome to our website, where we are dedicated to providing
                high-quality manpower solutions to businesses in need. We are
                always seeking partners who share our commitment to excellence
                and are interested in collaborating with us to provide top-notch
                manpower services to clients nationwide.
              </HeaderP>
            </div>
            <div className="col-md-6 flexCenter">
              <ImageWrapper>
                <Img
                  className="radius8"
                  src={p2}
                  alt="office"
                  style={{ zIndex: 9, paddingBottom: "65px" }}
                  width="100%"
                />
                <DotsWrapper>
                  <Dots />
                </DotsWrapper>
              </ImageWrapper>
              <GreyDiv className="lightBg"></GreyDiv>
            </div>
          </div>
          <div className="row ">
            <div className="col-md-6">
              <ImageWrapper>
                <Img
                  className="radius8"
                  src={p1}
                  alt="office"
                  style={{ zIndex: 9, paddingBottom: "65px" }}
                  width="100%"
                />
              </ImageWrapper>
            </div>
            <div className="col-md-6">
              <HeaderP className="font16 semiBold">
              As a valued partner with The Bonkur Group, your collaboration extends to our comprehensive suite of services, meticulously designed to streamline your workforce and development needs across diverse industries. Our seasoned team of professionals stands ready to collaborate closely with you, diligently comprehending your distinctive requirements and delivering bespoke solutions, including housing management and internship placement.
                <br /> <br />
                Whether you seek housing management services to provide comfortable living arrangements for your students and professionals or aspire to facilitate enriching internship opportunities, we are equipped to meet your demands with unwavering proficiency. Our far-reaching network of accomplished and seasoned professionals spans across a multitude of domains, including but not limited to security, engineering, healthcare, construction, IT, and an array of other fields. Your partnership with us signifies a commitment to excellence in workforce and development solutions, ensuring success in every facet of your endeavors.
              </HeaderP>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <HeaderP className="font16 semiBold">
              At The Bonkur Group, we take immense pride in our unwavering commitment to delivering top-notch quality and ensuring unparalleled customer satisfaction. We understand that the success of your business is closely intertwined with ours, and it is our steadfast pledge to furnish you with the unwavering support and resources necessary to achieve your goals. If you are contemplating the prospect of partnering with us to offer skilled and experienced manpower solutions to your valued clients, please reach out to us directly through the contact information provided. Your consideration means the world to us, and we eagerly await the opportunity to connect with you. Thank you for expressing your interest in our company; we look forward to the possibility of collaborating and achieving shared success together.
              </HeaderP>
            </div>
            <div className="col-md-6 flexCenter">
              <ImageWrapper>
                <Img
                  className="radius8"
                  src={p3}
                  alt="office"
                  style={{ zIndex: 9, paddingBottom: "65px" }}
                  width="100%"
                />
              </ImageWrapper>
              <GreyDiv className="lightBg"></GreyDiv>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 textCenter">
              <h2 className="extraBold font40">Our Partners</h2>
              {/* <ClientSlider/> */}
              <p>
                <span className="font20 semiBold">Coming Soon</span>
              </p>
            </div>
          </div>

          <div
            className="row"
            style={{
              marginTop: "20px",
            }}
          >
            <div className="col-md-12 textCenter">
              <h2>
                <span className="extraBold font40">Become a Partner</span>
              </h2>
              <p className="font16 semiBold flexCenter">
                We are always seeking partners who share our commitment to
                excellence and are interested in collaborating with us to
                provide top-notch manpower services to clients nationwide.
              </p>
              <br />
              <p className="font14 semiBold flexCenter">
                Please fill out the form below and one of our representatives
                will get in touch with you shortly.
              </p>
            </div>
          </div>
          <div
            className="row"
            style={{
              marginBottom: "20px",
            }}
          >
            <div className="col-md-12">
              <Form>
                <label className="font13">Name:</label>
                <input
                  type="text"
                  id="fname"
                  name="fname"
                  className="font20 extraBold"
                />
                <label className="font13">Email:</label>
                <input
                  type="text"
                  id="email"
                  name="email"
                  className="font20 extraBold"
                />
                <label className="font13">Phone:</label>
                <input
                  type="text"
                  id="phone"
                  name="phone"
                  className="font20 extraBold"
                />
                <label className="font13">Company:</label>
                <input
                  type="text"
                  id="company"
                  name="company"
                  className="font20 extraBold"
                />
                <label className="font13">Number Of Employees Required:</label>
                <input
                  type="number"
                  id="employees"
                  name="employees"
                  className="font20 extraBold"
                />
                <label className="font13">Job Title:</label>
                <input
                  type="text"
                  id="job"
                  name="job"
                  className="font20 extraBold"
                />
                <label className="font13">Message:</label>
                <textarea
                  rows="4"
                  cols="50"
                  type="text"
                  id="message"
                  name="message"
                  className="font20 extraBold"
                />
              </Form>
              <SumbitWrapper className="flex">
                <ButtonInput
                  type="submit"
                  value="Submit"
                  className="pointer animate radius8"
                  style={{ maxWidth: "220px" }}
                />
              </SumbitWrapper>
            </div>
          </div>
        </div>
      </Wrapper>
      <Footer />
    </>
  );
}

const Wrapper = styled.section`
  padding-top: 80px;
  width: 100%;
  min-height: 840px;
  margin-top: 100px;
  @media (max-width: 960px) {
    flex-direction: column;
    margin-top: 10px;
  }
`;
const LeftSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    margin: 20px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 30px 0 50px 0;
  }
`;
const RightSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin-top: 30px;
  }
`;
const HeaderP = styled.div`
  max-width: 470px;
  padding: 15px 0 50px 0;
  line-height: 1.5rem;
  @media (max-width: 960px) {
    padding: 15px 0 50px 0;
    text-align: center;
    max-width: 100%;
  }
`;
const BtnWrapper = styled.div`
  max-width: 190px;
  @media (max-width: 960px) {
    margin: 0 auto;
  }
`;
const GreyDiv = styled.div`
  width: 30%;
  height: 430px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  @media (max-width: 960px) {
    display: none;
  }
`;
const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 9;
  @media (max-width: 960px) {
    width: 100%;
    justify-content: center;
  }
`;
const Img = styled.img`
  @media (max-width: 960px) {
    width: 90%;
    height: auto;
    padding-bottom: 0;
  }
`;
const QuoteWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 50px;
  max-width: 330px;
  padding: 30px;
  z-index: 99;
  @media (max-width: 960px) {
    left: 20px;
  }
  @media (max-width: 560px) {
    bottom: -50px;
  }
`;
const QuotesWrapper = styled.div`
  position: absolute;
  left: -20px;
  top: -10px;
`;
const DotsWrapper = styled.div`
  position: absolute;
  right: -100px;
  bottom: 100px;
  z-index: 2;
  @media (max-width: 960px) {
    right: 100px;
  }
  @media (max-width: 560px) {
    display: none;
  }
`;
const Form = styled.form`
  padding: 70px 0 30px 0;
  input,
  textarea {
    width: 100%;
    background-color: transparent;
    border: 0px;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #707070;
    height: 30px;
    margin-bottom: 30px;
  }
  textarea {
    min-height: 100px;
  }
  @media (max-width: 860px) {
    padding: 30px 0;
  }
`;
const ButtonInput = styled.input`
  border: 1px solid #14117a;
  background-color: #14117a;
  width: 100%;
  padding: 15px;
  outline: none;
  color: #fff;
  :hover {
    background-color: #0d0b4e;
    border: 1px solid #7620ff;
    color: #fff;
  }
  @media (max-width: 991px) {
    margin: 0 auto;
  }
`;
const ContactImgBox = styled.div`
  max-width: 180px; 
  align-self: flex-end; 
  margin: 10px 30px 10px 0;
`;
const SumbitWrapper = styled.div`
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 50px;
  }
`;
