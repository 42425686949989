import React from "react";
import styled from "styled-components";
import FullButton from "../Buttons/FullButton";

export default function ProjectBox({ img, title, text, action,width}) {
  return (
    <Wrapper>
      <ImgBtn className="aniamte pointer" onClick={action ? () => action() : null}>
        <img className="radius8" src={img} style={{
            width: width ? width : "100%",
        }
          }
          alt="project"></img>
      </ImgBtn>
      <h3 className="font20 extraBold">{title}</h3>
      <p className="font13">{text}</p>
      <button className="animate pointer viewMore" onClick={action ? () => action() : null}>
          View More
      </button>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  margin-top: 30px;
  img {
    width: ${props => props.width ? props.width : "100%"};
    height: auto;
    margin: 20px 0;
  }
  h3 {
    padding-bottom: 10px;
  }
`;
const ImgBtn = styled.button`
  background-color: transparent;
  border: 0px;
  outline: none;
  padding: 0px;
  margin: 0px;
  :hover > img {
    opacity: 0.9;
    transform: scale(1.1);
    // smooth transition of the image
    transition: all 0.3s ease;
  }
`;