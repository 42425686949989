import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
// Assets
import Logo from "../../assets/img/logo-wbg.png"

export default function Contact() {
  return (
    <Wrapper>
      <div className="darkBg">
        <div className="container textCenter">
          <div className="flexSpaceCenter" style={{ padding: "30px 5px" }}>
            <div className=" row">
              <div className="col-md-4 textCenter">
                <div className="flexCenter">
                  <img src={Logo} alt="logo" style={{ width: "40px", height: "40px" }} />
                  <h1 className="font15 extraBold whiteColor" style={{ marginLeft: "15px" }}>
                    The Bonkur Group
                  </h1>
                </div>
                <p className="whiteColor font13" style={{ marginTop: "10px",marginBottom:"10px" }}>
                Welcome to our company, a leading provider of job opportunities, internships, and housing solutions suitable for both students and professionals across the United Kingdom. Our mission is to connect you with rewarding opportunities in various sectors while ensuring comfortable living arrangements. We're here to support you on your journey in the UK.
                </p>
                </div>
                <div className="col-md-4 " style={{marginLeft:"auto"}}>
                  <h1 className="font15 extraBold whiteColor" style={{ marginLeft: "15px" }}>
                    Address
                  </h1>
                  <p className="whiteColor font13" style={{ marginTop: "10px" }}>
                    <span className="goldColor font13">The Bonkur Group Of Industries Ltd.</span><br />
                    18 Fern Dells, Hatfield, England,
                    <br/> AL10 9HX
                    <br/>
                    <i className="bi bi-envelope" /> {' '} <a href="mailto:info@thebonkurgroup.com" className="whiteColor" style={{ textDecoration: "none"}}>info@thebonkurgroup.com</a><br/>
                    <i className="bi bi-telephone" /> {' '} +44 0786 4512 999
                  </p>
                  </div>
                  <div className="col-md-4" style={{marginRight:"auto"}}>
                    <h1 className="font15 extraBold whiteColor" style={{ marginLeft: "15px" }}>
                      Quick Links
                    </h1>
                    <ul className="whiteColor font13" style={{ marginTop: "10px" }}>
                      <li className="pointer">
                        <a href="/" className="whiteColor animate pointer font13">
                          Home
                        </a>
                      </li>
                      <li className="pointer">
                        <a href="/terms-and-conditions" className="whiteColor animate pointer font13">
                          Terms &<br/> Conditions
                        </a>
                      </li>
                      <li className="pointer">
                        <a href="/privacy-policy" className="whiteColor animate pointer font13">
                          Privacy Policy
                        </a>
                      </li>
                      <li className="pointer">
                        <a href="/disclaimer" className="whiteColor animate pointer font13">
                          Disclaimer
                        </a>
                      </li>
                    </ul>
                    </div>
                  </div>
            </div>
            
        </div>
        <div className="container textCenter">
          <InnerWrapper className="flexSpaceCenter1" style={{ padding: "10px 0" }}>
            <StyleP className="whiteColor font13">
              © {2022} - <span className="goldColor font13">The Bonkur Group Of Industries Ltd.</span> All Right Reserved
            </StyleP>
          </InnerWrapper>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;
const InnerWrapper = styled.div`
  @media (max-width: 550px) {
    flex-direction: column;
  }
`;
const StyleP = styled.p`
  @media (max-width: 550px) {
    margin: 20px 0;
  }
`;
