import React from "react";
import styled from "styled-components";
// Components
import FullButton from "../components/Buttons/FullButton";
import TopNavbar from "../components/Nav/TopNavbar";
import Footer from "../components/Sections/Footer";
// Assets
import AboutImg1 from "../assets/img/about-us-1.jpg";
import AboutImg2 from "../assets/img/about-us-2.jpg";
import AboutImg3 from "../assets/img/about-us-3.webp";
import KarthikImg from "../assets/img/Karthik.jpeg";
import Dots from "../assets/svg/Dots";

export default function AboutUsPage() {
  return (
    <>
      <TopNavbar />
          <Wrapper id="aboutuspage" className="container flexSpaceCenter">
            <div className="container ">
              <div className="row">
                <div className="col-md-6">
                <h1 className="extraBold font60"> 
                        About Us
                    </h1>
                    <HeaderP className="font16 semiBold">
                    Welcome to our company, a leading provider of job opportunities across the United Kingdom. Our mission is to connect job seekers with rewarding employment opportunities in a variety of sectors, including security services, cleaning, fire marshals, care workers, and many others.
                    </HeaderP>
                </div>
                <div className="col-md-6 flexCenter">
                <ImageWrapper>
                    <Img className="radius8" src={AboutImg1} alt="office" style={{zIndex: 9,paddingBottom:"65px"}} width="100%" />
                    <DotsWrapper>
                      <Dots />
                    </DotsWrapper>
                  </ImageWrapper>
                  <GreyDiv className="lightBg"></GreyDiv>
                </div>
              </div>
              <div className="row ">
                  <div className="col-md-6">
                  <ImageWrapper>
                      <Img className="radius8" src={AboutImg2} alt="office" style={{zIndex: 9,paddingBottom:"65px"}} width="100%" />
                    </ImageWrapper>
                  </div>
                  <div className="col-md-6">
                      <HeaderP className="font16 semiBold">
                      We are proud to be partnered with high-level companies across the UK, which enables us to provide job opportunities in locations throughout the country. Our extensive network of employers includes a wide range of businesses and organizations, from small local firms to large multinational corporations. This diversity allows us to offer a variety of job opportunities to our candidates.
                      <br/> <br/>
                      At our company, we are committed to providing high-quality jobs that offer competitive salaries, flexible hours, and opportunities for growth and advancement. We believe that finding a job you love should be a fulfilling and enjoyable experience, and we strive to make that a reality for our candidates.
                      </HeaderP>
                  </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                    <HeaderP className="font16 semiBold">
                    Whether you are a recent graduate, an experienced professional, or someone looking for a change in career, we can help you train and find the right job in your preferred sector. Our team of experienced recruiters will work with you to understand your career goals and match you with job opportunities that fit your skills and interests.
                    <br/> <br/>
                    We take pride in the quality of the services we provide and are dedicated to helping our candidates succeed in their careers. If you are looking for a new opportunity in a sector you are passionate about, we invite you to join our network of skilled professionals. Explore our website to learn more about our services and the job opportunities available to you. Thank you for considering us as your partner in finding your dream job.
                    </HeaderP>
                </div>
                <div className="col-md-6 flexCenter">
                <ImageWrapper>
                    <Img className="radius8" src={AboutImg3} alt="office" style={{zIndex: 9,paddingBottom:"65px"}} width="100%" />
                  </ImageWrapper>
                  <GreyDiv className="lightBg"></GreyDiv>
                </div>
              </div>
            </div>
          </Wrapper>
      <Footer/>
    </>
  );
}


const Wrapper = styled.section`
  padding-top: 80px;
  width: 100%;
  min-height: 840px;
  margin-top: 100px;
  @media (max-width: 960px) {
    flex-direction: column;
    margin-top: 10px;
  }
`;
const LeftSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    margin: 20px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 30px 0 50px 0;
  }
`;
const RightSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin-top: 30px;
  }
`;
const HeaderP = styled.div`
  max-width: 470px;
  padding: 15px 0 50px 0;
  line-height: 1.5rem;
  @media (max-width: 960px) {
    padding: 15px 0 50px 0;
    text-align: center;
    max-width: 100%;
  }
`;
const BtnWrapper = styled.div`
  max-width: 190px;
  @media (max-width: 960px) {
    margin: 0 auto;
  }
`;
const GreyDiv = styled.div`
  width: 30%;
  height: 430px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  @media (max-width: 960px) {
    display: none;
  }
`;
const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-center;
  position: relative;
  z-index: 9;
  @media (max-width: 960px) {
    width: 100%;
    justify-content: center;
  }
`;
const Img = styled.img`
  @media (max-width: 960px) {
    width: 90%;
    height: auto;
    padding-bottom: 0;
  }
`;
const QuoteWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 50px;
  max-width: 330px;
  padding: 30px;
  z-index: 99;
  @media (max-width: 960px) {
    left: 20px;
  }
  @media (max-width: 560px) {
    bottom: -50px;
  }
`;
const QuotesWrapper = styled.div`
  position: absolute;
  left: -20px;
  top: -10px;
`;
const DotsWrapper = styled.div`
  position: absolute;
  right: -100px;
  bottom: 100px;
  z-index: 2;
  @media (max-width: 960px) {
    right: 100px;
  }
  @media (max-width: 560px) {
    display: none;
  }
`;
const Form = styled.form`
  padding: 70px 0 30px 0;
  input,
  textarea {
    width: 100%;
    background-color: transparent;
    border: 0px;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #707070;
    height: 30px;
    margin-bottom: 30px;
  }
  textarea {
    min-height: 100px;
  }
  @media (max-width: 860px) {
    padding: 30px 0;
  }
`;
const ButtonInput = styled.input`
  border: 1px solid #14117a;
  background-color: #14117a;
  width: 100%;
  padding: 15px;
  outline: none;
  color: #fff;
  :hover {
    background-color: #0d0b4e;
    border: 1px solid #7620ff;
    color: #fff;
  }
  @media (max-width: 991px) {
    margin: 0 auto;
  }
`;
const ContactImgBox = styled.div`
  max-width: 180px; 
  align-self: flex-end; 
  margin: 10px 30px 10px 0;
`;
const SumbitWrapper = styled.div`
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 50px;
  }
`;


