import React from "react";
import styled from "styled-components";
// Components
import FullButton from "../Buttons/FullButton";
// Assets
import HeaderImage from "../../assets/img/header-img.jpg";
import QuotesIcon from "../../assets/svg/Quotes";
import Dots from "../../assets/svg/Dots";
import { Link } from "react-scroll";
import { scroller } from "react-scroll";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import security from "../../assets/img/security.jpg";
import internship from "../../assets/img/internship.jpg";
import house from "../../assets/img/house.jpg";
export default function HomeC() {
  return (
    <div>
      <Carousel showThumbs={false} infiniteLoop={true} renderIndicator={false}>
        {/*security services*/}
        <div
          style={{
            backgroundImage: `url("https://s0.hfdstatic.com/sites/the_hartford/pubimgs/1616640462223.jpg?v=2022-05-06_140222522)`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: "100vh",
          }}
        >
          <div style={{
            backgroundColor: "#b2adad8c",
            height: "100vh",
          }}>
          <Wrapper className="container" >
            <LeftSide>
              <div>
                <h1 className="font40" style={{
                    marginBottom: "10px",
                    fontWeight: "700",
                }}>Welcome to The Bonkur Group</h1>
                <HeaderP className="font16 semiBold">
                We provide a range of comprehensive security solutions designed to safeguard assets and ensure the safety of personnel
                </HeaderP>
                <BtnWrapper>
                  <FullButton
                    title="Get Started"
                    action={() =>
                      scroller.scrollTo("services", {
                        smooth: true,
                        offset: -70,
                        duration: 300,
                      })
                    }
                  />
                </BtnWrapper>
              </div>
            </LeftSide>
          </Wrapper>
          </div>
        </div>
        {/*student internships*/}
        <div
          style={{
            backgroundImage: `url(${internship})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: "100%",
          }}
        >
          <div style={{
            backgroundColor: "#b2adad8c",
            height: "100%",
          }}>
          <Wrapper className="container" >
            <LeftSide>
              <div>
                <h1 className="font40" style={{
                    marginBottom: "10px",
                    fontWeight: "700",
                }}>Welcome to The Bonkur Group</h1>
                <HeaderP className="font16 semiBold">
                Explore valuable learning opportunities and gain practical experience through our student internship programs.
                </HeaderP>
                <BtnWrapper>
                  <FullButton
                    title="Get Started"
                    action={() =>
                      scroller.scrollTo("services", {
                        smooth: true,
                        offset: -70,
                        duration: 300,
                      })
                    }
                  />
                </BtnWrapper>
              </div>
            </LeftSide>
          </Wrapper>
          </div>
        </div>
        {/*housing management*/}
        <div
          style={{
            backgroundImage: `url(${house})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: "100%",
          }}
        >
          <div style={{
            backgroundColor: "#b2adad8c",
            height: "100%",
          }}>
          <Wrapper className="container" >
            <LeftSide>
              <div>
                <h1 className="font40" style={{
                    marginBottom: "10px",
                    fontWeight: "700",
                }}>Welcome to The Bonkur Group</h1>
                <HeaderP className="font16 semiBold">
                Our housing management services ensure comfortable and well-maintained living spaces for residents.
                </HeaderP>
                <BtnWrapper>
                  <FullButton
                    title="Get Started"
                    action={() =>
                      scroller.scrollTo("services", {
                        smooth: true,
                        offset: -70,
                        duration: 300,
                      })
                    }
                  />
                </BtnWrapper>
              </div>
            </LeftSide>
          </Wrapper>
          </div>
        </div>
      </Carousel>
    </div>
  );
}

const Wrapper = styled.section`
  padding-top: 40vh;
  width: 100%;
  max-height: 100%
  margin-bottom: 300px;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;
const LeftSide = styled.div`
  width: 70%;
  height: 100%;
  text-align: left;
  justify-content: center;
  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    margin: 50px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 2px 0 50px 0;
  }
`;
const RightSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin-top: 30px;
  }
`;
const HeaderP = styled.div`
  max-width: 605px;
  padding: 15px 0 50px 0;
  line-height: 1.5rem;
  @media (max-width: 960px) {
    padding: 15px 0 50px 0;
    text-align: center;
    max-width: 100%;
  }
`;
const BtnWrapper = styled.div`
  max-width: 190px;
  @media (max-width: 960px) {
    margin: 0 auto;
  }
`;
const GreyDiv = styled.div`
  width: 30%;
  height: 700px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  @media (max-width: 960px) {
    display: none;
  }
`;
const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 9;
  @media (max-width: 960px) {
    width: 100%;
    justify-content: center;
  }
`;
const Img = styled.img`
  @media (max-width: 560px) {
    width: 80%;
    height: auto;
  }
`;
const QuoteWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 50px;
  max-width: 330px;
  padding: 30px;
  z-index: 99;
  @media (max-width: 960px) {
    left: 20px;
  }
  @media (max-width: 560px) {
    bottom: -50px;
  }
`;
const QuotesWrapper = styled.div`
  position: absolute;
  left: -20px;
  top: -10px;
`;
const DotsWrapper = styled.div`
  position: absolute;
  right: -100px;
  bottom: 100px;
  z-index: 2;
  @media (max-width: 960px) {
    right: 100px;
  }
  @media (max-width: 560px) {
    display: none;
  }
`;
