import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
import { scroller } from "react-scroll";
// Components
import Sidebar from "../Nav/Sidebar";
import Backdrop from "../Elements/Backdrop";
// Assets
import LogoIcon from "../../assets/svg/Logo";
import BurgerIcon from "../../assets/svg/BurgerIcon";
import Logo from "../../assets/img/logo.png"

export default function TopNavbar() {
  const [y, setY] = useState(window.scrollY);
  const [sidebarOpen, toggleSidebar] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => setY(window.scrollY));
    return () => {
      window.removeEventListener("scroll", () => setY(window.scrollY));
    };
  }, [y]);

  var isOtherNav = window.location.pathname !== "/"; // true or false
  return (
    <>
      <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
      {sidebarOpen && <Backdrop toggleSidebar={toggleSidebar} />}
      <Wrapper className="flexCenter animate whiteBg" style={y > 100 ? { height: "60px" } : { height: "60px" }}>
        <NavInner className="container flexSpaceCenter">
          <a href="/" className="pointer flexNullCenter">
            <img src={Logo} alt="logo" style={{ width: "40px", height: "40px" }} />
            <h1 style={{ marginLeft: "15px" }} className="font30 extraBold navhead">
              The Bonkur Group
            </h1>
          </a>
          <BurderWrapper className="pointer" onClick={() => toggleSidebar(!sidebarOpen)}>
            <BurgerIcon />
          </BurderWrapper>
          <UlWrapper className="flexNullCenter">
            <li className="semiBold font15 pointer">
              {
                  isOtherNav ? (
                    <>
                      <a href="/#" >
                        <span className="font15 pointer" style={{ padding: "10px 15px" }}>Home</span>
                      </a>
                    </>
                    ) : (
                      <Link activeClass="active" style={{ padding: "10px 15px" }} to="home" spy={true} smooth={true} offset={-80}>
                          Home
                      </Link>
                    )
              }
            </li>
            <li className="semiBold font15 pointer">
              {
                  isOtherNav ? (
                    <>
                      <a href="/#services" >
                        <span className="font15 pointer" style={{ padding: "10px 15px" }}>Services</span>
                      </a>
                    </>
                    ) : (
                        <Link activeClass="active" style={{ padding: "10px 15px" }} to="services" spy={true} smooth={true} offset={-80}>
                          Services
                        </Link>
                    )
              }
            </li>
            <li className="semiBold font15 pointer">
              {
                  isOtherNav ? (
                    <>
                      <a href="/#aboutus" >
                        <span className="font15 pointer" style={{ padding: "10px 15px" }}>About Us</span>
                      </a>
                    </>
                    ) : (
                        <Link activeClass="active" style={{ padding: "10px 15px" }} to="aboutus" spy={true} smooth={true} offset={-80}>
                          About Us
                        </Link>
                    )
              }
            </li>
            <li className="semiBold font15 pointer">
              <a href="/partners" >
                <span className="font15 pointer" style={{ padding: "10px 15px" }}>Our Partners</span>
              </a>
            </li>
            <li className="semiBold font15 pointer">
              {
                  isOtherNav ? (
                    <>
                      <a 
                          href="/#contact"  
                          onClick={() =>{
                            scroller.scrollTo('contact', {
                                smooth: true,
                                offset: -70,
                                duration: 100,
                            })
                        }}
                      >
                        <span className="font15 pointer" style={{ padding: "10px 15px" }}>Contact Us</span>
                      </a>
                    </>
                    ) : (
                      <Link activeClass="active" style={{ padding: "10px 15px" }} to="contact" spy={true} smooth={true} offset={-80}>
                        Contact Us
                      </Link>
                    )
              }
            </li>
          </UlWrapper>
          {/* <UlWrapperRight className="flexNullCenter">
            <li className="semiBold font15 pointer flexCenter">
              <Link activeClass="active" style={{ padding: "10px 15px" }} to="services" spy={true} smooth={true} offset={-80}>
                 Get Started
              </Link>
            </li>
          </UlWrapperRight> */}
        </NavInner>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.nav`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
`;
const NavInner = styled.div`
  position: relative;
  height: 100%;
`
const BurderWrapper = styled.button`
  outline: none;
  border: 0px;
  background-color: transparent;
  height: 100%;
  padding: 0 15px;
  display: none;
  @media (max-width: 760px) {
    display: block;
  }
`;
const UlWrapper = styled.ul`
  display: flex;
  @media (max-width: 760px) {
    display: none;
  }
`;
const UlWrapperRight = styled.ul`
  @media (max-width: 760px) {
    display: none;
  }
`;


