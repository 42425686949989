import React from "react";
import styled from "styled-components";
// Components
import TopNavbar from "../components/Nav/TopNavbar";
import Footer from "../components/Sections/Footer";
// Assets
import Img1 from "../assets/img/services/1.png";
import Img2 from "../assets/img/services/sia-3.jpg";
import Img3 from "../assets/img/services/sia-2.webp";
import Dots from "../assets/svg/Dots";
import students from "../assets/img/students.jpg";
import internship from "../assets/img/internship.jpg";
import students1 from "../assets/img/students-1.jpg";
import students2 from "../assets/img/students-2.jpg";

import JoinUs from "../components/Sections/JoinUs";

export default function Internships() {
  return (
    <>
      <TopNavbar />
      <Wrapper id="siatraining" className="container flexSpaceCenter">
        <div className="container ">
          <div className="row">
            <div className="col-md-6">
              <h1 className="extraBold font60">Internships</h1>
              <HeaderP className="font16 semiBold">
              <span className="extraBold font20">
                Unlock Your Future with Transformative Internships</span> 
              <br/><br/>
              Embark on a journey of growth and discovery through our exceptional internship opportunities. Aspiring professionals, our curated internships are designed to provide you with real-world experiences, skill development, and the chance to make a meaningful impact.
              </HeaderP>
            </div>
            <div className="col-md-6 flexCenter">
              <ImageWrapper>
                <Img
                  className="radius8"
                  src={students1}
                  alt="office"
                  style={{ zIndex: 9, paddingBottom: "65px" }}
                  width="100%"
                />
                <DotsWrapper>
                  <Dots />
                </DotsWrapper>
              </ImageWrapper>
              <GreyDiv className="lightBg"></GreyDiv>
            </div>
          </div>
          <div className="row ">
            <div className="col-md-6">
              <ImageWrapper>
                <Img
                  className="radius8"
                  src={students2}
                  alt="office"
                  style={{ zIndex: 9, paddingBottom: "65px" }}
                  width="100%"
                />
              </ImageWrapper>
            </div>
            <div className="col-md-6">
              <HeaderP className="font16 semiBold">
                <br /> <br />
                Join hands with industry experts, work on dynamic projects, and immerse yourself in a collaborative environment that nurtures creativity and innovation. Our internships span various fields, offering you a platform to explore your passions and refine your expertise.
                <br/><br/>
                Step into a world of learning, networking, and career advancement. Your future begins here. Explore our diverse internships and start shaping your path today.
                </HeaderP>
            </div>
          </div>
          {/* <JoinUs service="SIA Training" /> */}
        </div>
      </Wrapper>
      <Footer />
    </>
  );
}

const Wrapper = styled.section`
  padding-top: 80px;
  width: 100%;
  min-height: 840px;
  margin-top: 100px;
  @media (max-width: 960px) {
    flex-direction: column;
    margin-top: 10px;
  }
`;
const LeftSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    margin: 20px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 30px 0 50px 0;
  }
`;
const RightSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin-top: 30px;
  }
`;
const HeaderP = styled.div`
  max-width: 470px;
  padding: 15px 0 50px 0;
  line-height: 1.5rem;
  @media (max-width: 960px) {
    padding: 15px 0 50px 0;
    text-align: center;
    max-width: 100%;
  }
`;
const BtnWrapper = styled.div`
  max-width: 190px;
  @media (max-width: 960px) {
    margin: 0 auto;
  }
`;
const GreyDiv = styled.div`
  width: 30%;
  height: 430px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  @media (max-width: 960px) {
    display: none;
  }
`;
const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 9;
  @media (max-width: 960px) {
    width: 100%;
    justify-content: center;
  }
`;
const Img = styled.img`
  @media (max-width: 960px) {
    width: 90%;
    height: auto;
    padding-bottom: 0;
  }
`;
const QuoteWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 50px;
  max-width: 330px;
  padding: 30px;
  z-index: 99;
  @media (max-width: 960px) {
    left: 20px;
  }
  @media (max-width: 560px) {
    bottom: -50px;
  }
`;
const QuotesWrapper = styled.div`
  position: absolute;
  left: -20px;
  top: -10px;
`;
const DotsWrapper = styled.div`
  position: absolute;
  right: -100px;
  bottom: 100px;
  z-index: 2;
  @media (max-width: 960px) {
    right: 100px;
  }
  @media (max-width: 560px) {
    display: none;
  }
`;
const Form = styled.form`
  padding: 70px 0 30px 0;
  input,
  textarea {
    width: 100%;
    background-color: transparent;
    border: 0px;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #707070;
    height: 30px;
    margin-bottom: 30px;
  }
  textarea {
    min-height: 100px;
  }
  @media (max-width: 860px) {
    padding: 30px 0;
  }
`;
const ButtonInput = styled.input`
  border: 1px solid #14117a;
  background-color: #14117a;
  width: 100%;
  padding: 15px;
  outline: none;
  color: #fff;
  :hover {
    background-color: #0d0b4e;
    border: 1px solid #7620ff;
    color: #fff;
  }
  @media (max-width: 991px) {
    margin: 0 auto;
  }
`;
const ContactImgBox = styled.div`
  max-width: 180px; 
  align-self: flex-end; 
  margin: 10px 30px 10px 0;
`;
const SumbitWrapper = styled.div`
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 50px;
  }
`;
