import React from "react";
import styled from "styled-components";
// Components
import TopNavbar from "../components/Nav/TopNavbar";
import Footer from "../components/Sections/Footer";
// Assets
import Img1 from "../assets/img/services/3.jpg";
import Img2 from "../assets/img/services/cs-1.jpg";
import Img3 from "../assets/img/services/cs-2.jpg";
import Img4 from "../assets/img/services/cs-3.jpg";
import Dots from "../assets/svg/Dots";
import JoinUs from "../components/Sections/JoinUs";

export default function CleaningServices() {
  return (
    <>
      <TopNavbar />
      <Wrapper id="siatraining" className="container flexSpaceCenter">
        <div className="container ">
          <div className="row">
            <div className="col-md-6">
              <h1 className="extraBold font60">Cleaners</h1>
              <HeaderP className="font16 semiBold">
                Join Our Cleaning Team and Get More Jobs!
                <br />
                <br />
                We are a growing cleaning service business that is looking for
                dedicated and hardworking cleaners to join our team. As we
                expand our operations, we need more cleaners to help us meet the
                demands of our growing clientele.
              </HeaderP>
            </div>
            <div className="col-md-6 flexCenter">
              <ImageWrapper>
                <Img
                  className="radius8"
                  src={Img1}
                  alt="office"
                  style={{ zIndex: 9, paddingBottom: "65px" }}
                  width="100%"
                />
                <DotsWrapper>
                  <Dots />
                </DotsWrapper>
              </ImageWrapper>
              <GreyDiv className="lightBg"></GreyDiv>
            </div>
          </div>
          <div className="row ">
            <div className="col-md-6">
              <ImageWrapper>
                <Img
                  className="radius8"
                  src={Img2}
                  alt="office"
                  style={{ zIndex: 9, paddingBottom: "65px" }}
                  width="100%"
                />
              </ImageWrapper>
            </div>
            <div className="col-md-6">
              <HeaderP className="font16 semiBold">
                Why Join Our Team?
                <br />
                More job opportunities: As a member of our cleaning team, you'll
                have access to a steady stream of job opportunities. We have a
                growing list of residential and commercial clients who need our
                cleaning services, and we need more cleaners to help us meet
                their needs.
                <br /> <br />
                Flexible schedule: We understand that our cleaners have
                different schedules and availability. That's why we offer a
                flexible schedule that allows you to work when it's convenient
                for you. Whether you're looking for a full-time or part-time
                job, we have opportunities that fit your schedule.
              </HeaderP>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <HeaderP className="font16 semiBold">
                Competitive pay: We offer competitive pay rates for our
                cleaners, ensuring that you get the compensation you deserve for
                your hard work. We believe in paying our cleaners fairly for the
                excellent work they do.
                <br />
                <br />
                Training and support: We provide training and support to our
                cleaners to help them improve their skills and perform their
                jobs better. Our team of experienced cleaners will guide you
                through the cleaning process and provide feedback to help you
                improve.
              </HeaderP>
            </div>
            <div className="col-md-6 flexCenter">
              <ImageWrapper>
                <Img
                  className="radius8"
                  src={Img3}
                  alt="office"
                  style={{ zIndex: 9, paddingBottom: "65px" }}
                  width="100%"
                />
              </ImageWrapper>
              <GreyDiv className="lightBg"></GreyDiv>
            </div>
          </div>
          <div className="row ">
            <div className="col-md-6">
              <ImageWrapper>
                <Img
                  className="radius8"
                  src={Img4}
                  alt="office"
                  style={{ zIndex: 9, paddingBottom: "65px" }}
                  width="100%"
                />
              </ImageWrapper>
            </div>
            <div className="col-md-6">
              <HeaderP className="font16 semiBold">
                A supportive work environment: We believe in creating a
                supportive work environment where our cleaners can thrive. We
                value our cleaners and treat them with respect, ensuring that
                they feel appreciated and valued for their contributions. Join
                Our Team Today!
                <br />
                <br />
                If you're looking for a rewarding job that allows you to make a
                difference in people's lives, join our cleaning team today! We
                offer a supportive work environment, flexible schedule,
                competitive pay, and more job opportunities than ever before.
                Contact us today to learn more about our cleaning team and how
                you can join us.
              </HeaderP>
            </div>
          </div>
          {/* <JoinUs service="Cleaner" /> */}
        </div>
      </Wrapper>
      <Footer />
    </>
  );
}

const Wrapper = styled.section`
  padding-top: 80px;
  width: 100%;
  min-height: 840px;
  margin-top: 100px;
  @media (max-width: 960px) {
    flex-direction: column;
    margin-top: 10px;
  }
`;
const LeftSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    margin: 20px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 30px 0 50px 0;
  }
`;
const RightSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin-top: 30px;
  }
`;
const HeaderP = styled.div`
  max-width: 470px;
  padding: 15px 0 50px 0;
  line-height: 1.5rem;
  @media (max-width: 960px) {
    padding: 15px 0 50px 0;
    text-align: center;
    max-width: 100%;
  }
`;
const BtnWrapper = styled.div`
  max-width: 190px;
  @media (max-width: 960px) {
    margin: 0 auto;
  }
`;
const GreyDiv = styled.div`
  width: 30%;
  height: 430px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  @media (max-width: 960px) {
    display: none;
  }
`;
const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 9;
  @media (max-width: 960px) {
    width: 100%;
    justify-content: center;
  }
`;
const Img = styled.img`
  @media (max-width: 960px) {
    width: 90%;
    height: auto;
    padding-bottom: 0;
  }
`;
const QuoteWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 50px;
  max-width: 330px;
  padding: 30px;
  z-index: 99;
  @media (max-width: 960px) {
    left: 20px;
  }
  @media (max-width: 560px) {
    bottom: -50px;
  }
`;
const QuotesWrapper = styled.div`
  position: absolute;
  left: -20px;
  top: -10px;
`;
const DotsWrapper = styled.div`
  position: absolute;
  right: -100px;
  bottom: 100px;
  z-index: 2;
  @media (max-width: 960px) {
    right: 100px;
  }
  @media (max-width: 560px) {
    display: none;
  }
`;
const Form = styled.form`
  padding: 70px 0 30px 0;
  input,
  textarea {
    width: 100%;
    background-color: transparent;
    border: 0px;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #707070;
    height: 30px;
    margin-bottom: 30px;
  }
  textarea {
    min-height: 100px;
  }
  @media (max-width: 860px) {
    padding: 30px 0;
  }
`;
const ButtonInput = styled.input`
  border: 1px solid #14117a;
  background-color: #14117a;
  width: 100%;
  padding: 15px;
  outline: none;
  color: #fff;
  :hover {
    background-color: #0d0b4e;
    border: 1px solid #7620ff;
    color: #fff;
  }
  @media (max-width: 991px) {
    margin: 0 auto;
  }
`;
const ContactImgBox = styled.div`
  max-width: 180px; 
  align-self: flex-end; 
  margin: 10px 30px 10px 0;
`;
const SumbitWrapper = styled.div`
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 50px;
  }
`;
