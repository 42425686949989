import React from "react";
import styled from "styled-components";
// Components
import TopNavbar from "../components/Nav/TopNavbar";
import Footer from "../components/Sections/Footer";
// Assets
import Img1 from "../assets/img/services/1.png";
import Img2 from "../assets/img/services/sia-3.jpg";
import Img3 from "../assets/img/services/sia-2.webp";
import Dots from "../assets/svg/Dots";
import JoinUs from "../components/Sections/JoinUs";

export default function SIATraining() {
  return (
    <>
      <TopNavbar />
      <Wrapper id="siatraining" className="container flexSpaceCenter">
        <div className="container ">
          <div className="row">
            <div className="col-md-6">
              <h1 className="extraBold font60">SIA Training</h1>
              <HeaderP className="font16 semiBold">
                Looking for the best SIA training program in town? Look no
                further! Our comprehensive training courses are designed to
                prepare contractors and workers for success in the security
                industry.
              </HeaderP>
            </div>
            <div className="col-md-6 flexCenter">
              <ImageWrapper>
                <Img
                  className="radius8"
                  src={Img1}
                  alt="office"
                  style={{ zIndex: 9, paddingBottom: "65px" }}
                  width="100%"
                />
                <DotsWrapper>
                  <Dots />
                </DotsWrapper>
              </ImageWrapper>
              <GreyDiv className="lightBg"></GreyDiv>
            </div>
          </div>
          <div className="row ">
            <div className="col-md-6">
              <ImageWrapper>
                <Img
                  className="radius8"
                  src={Img2}
                  alt="office"
                  style={{ zIndex: 9, paddingBottom: "65px" }}
                  width="100%"
                />
              </ImageWrapper>
            </div>
            <div className="col-md-6">
              <HeaderP className="font16 semiBold">
                At our training center, you'll learn everything you need to know
                to become a skilled security professional, from the latest
                industry regulations to advanced tactics for handling
                challenging situations. Our experienced instructors have years
                of hands-on experience in the field, so you can be sure you're
                getting the most up-to-date information and training available.
                <br /> <br />
                We offer a range of training courses to meet the needs of
                different contractors and workers, including courses for SIA
                door supervisors, security guards, and CCTV operators. Whether
                you're just starting out in the industry or you're looking to
                upgrade your skills and knowledge, we have a course that's right
                for you.
              </HeaderP>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <HeaderP className="font16 semiBold">
                Our courses are flexible and affordable, with convenient
                schedules that fit around your busy lifestyle. We also offer
                personalized support and guidance to help you succeed, with
                one-on-one coaching and mentoring to help you reach your goals.
                <br /> <br />
                Don't settle for second-rate training – choose the best SIA
                training program in town and get the skills you need to succeed
                in the security industry. Contact us today to learn more about
                our courses and enrollment options.
              </HeaderP>
            </div>
            <div className="col-md-6 flexCenter">
              <ImageWrapper>
                <Img
                  className="radius8"
                  src={Img3}
                  alt="office"
                  style={{ zIndex: 9, paddingBottom: "65px" }}
                  width="100%"
                />
              </ImageWrapper>
              <GreyDiv className="lightBg"></GreyDiv>
            </div>
          </div>
          {/* <JoinUs service="SIA Training" /> */}
        </div>
      </Wrapper>
      <Footer />
    </>
  );
}

const Wrapper = styled.section`
  padding-top: 80px;
  width: 100%;
  min-height: 840px;
  margin-top: 100px;
  @media (max-width: 960px) {
    flex-direction: column;
    margin-top: 10px;
  }
`;
const LeftSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    margin: 20px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 30px 0 50px 0;
  }
`;
const RightSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin-top: 30px;
  }
`;
const HeaderP = styled.div`
  max-width: 470px;
  padding: 15px 0 50px 0;
  line-height: 1.5rem;
  @media (max-width: 960px) {
    padding: 15px 0 50px 0;
    text-align: center;
    max-width: 100%;
  }
`;
const BtnWrapper = styled.div`
  max-width: 190px;
  @media (max-width: 960px) {
    margin: 0 auto;
  }
`;
const GreyDiv = styled.div`
  width: 30%;
  height: 430px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  @media (max-width: 960px) {
    display: none;
  }
`;
const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 9;
  @media (max-width: 960px) {
    width: 100%;
    justify-content: center;
  }
`;
const Img = styled.img`
  @media (max-width: 960px) {
    width: 90%;
    height: auto;
    padding-bottom: 0;
  }
`;
const QuoteWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 50px;
  max-width: 330px;
  padding: 30px;
  z-index: 99;
  @media (max-width: 960px) {
    left: 20px;
  }
  @media (max-width: 560px) {
    bottom: -50px;
  }
`;
const QuotesWrapper = styled.div`
  position: absolute;
  left: -20px;
  top: -10px;
`;
const DotsWrapper = styled.div`
  position: absolute;
  right: -100px;
  bottom: 100px;
  z-index: 2;
  @media (max-width: 960px) {
    right: 100px;
  }
  @media (max-width: 560px) {
    display: none;
  }
`;
const Form = styled.form`
  padding: 70px 0 30px 0;
  input,
  textarea {
    width: 100%;
    background-color: transparent;
    border: 0px;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #707070;
    height: 30px;
    margin-bottom: 30px;
  }
  textarea {
    min-height: 100px;
  }
  @media (max-width: 860px) {
    padding: 30px 0;
  }
`;
const ButtonInput = styled.input`
  border: 1px solid #14117a;
  background-color: #14117a;
  width: 100%;
  padding: 15px;
  outline: none;
  color: #fff;
  :hover {
    background-color: #0d0b4e;
    border: 1px solid #7620ff;
    color: #fff;
  }
  @media (max-width: 991px) {
    margin: 0 auto;
  }
`;
const ContactImgBox = styled.div`
  max-width: 180px; 
  align-self: flex-end; 
  margin: 10px 30px 10px 0;
`;
const SumbitWrapper = styled.div`
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 50px;
  }
`;
