import React from "react";
import styled from "styled-components";
import { Link, scroller } from "react-scroll";
// Assets
import CloseIcon from "../../assets/svg/CloseIcon";
import Logo from "../../assets/img/logo-wbg.png";

export default function Sidebar({ sidebarOpen, toggleSidebar }) {
  var isOtherNav = window.location.pathname !== "/"; // true or false
  return (
    <Wrapper className="animate darkBg" sidebarOpen={sidebarOpen}>
      <SidebarHeader className="flexSpaceCenter">
        <div className="flexNullCenter">
          <a href="/" className="pointer flexNullCenter">
            <img
              src={Logo}
              alt="logo"
              style={{ width: "40px", height: "40px" }}
            />
            <h1
              style={{ marginLeft: "15px" }}
              className="whiteColor font20 extraBold navhead"
            >
              The Bonkur Group
            </h1>
          </a>
        </div>
        <CloseBtn
          onClick={() => toggleSidebar(!sidebarOpen)}
          className="animate pointer"
        >
          <CloseIcon />
        </CloseBtn>
      </SidebarHeader>

      <UlStyle className="flexNullCenter flexColumn">
        <li className="semiBold font15 pointer">
          {isOtherNav ? (
            <>
              <a href="/#">
                <span
                  className="font15 pointer whiteColor"
                  style={{ padding: "10px 15px" }}
                >
                  Home
                </span>
              </a>
            </>
          ) : (
            <Link
              onClick={() => toggleSidebar(!sidebarOpen)}
              activeClass="active"
              className="whiteColor"
              style={{ padding: "10px 15px" }}
              to="home"
              spy={true}
              smooth={true}
              offset={-60}
            >
              Home
            </Link>
          )}
        </li>
        <li className="semiBold font15 pointer">
          {isOtherNav ? (
            <>
              <a href="/#services">
                <span
                  className="font15 pointer whiteColor"
                  style={{ padding: "10px 15px" }}
                >
                  Services
                </span>
              </a>
            </>
          ) : (
            <Link
              onClick={() => toggleSidebar(!sidebarOpen)}
              activeClass="active"
              className="whiteColor"
              style={{ padding: "10px 15px" }}
              to="services"
              spy={true}
              smooth={true}
              offset={-60}
            >
              Services
            </Link>
          )}
        </li>
        <li className="semiBold font15 pointer">
          {isOtherNav ? (
            <>
              <a href="/#aboutus">
                <span
                  className="font15 pointer whiteColor"
                  style={{ padding: "10px 15px" }}
                >
                  About Us
                </span>
              </a>
            </>
          ) : (
            <Link
              onClick={() => toggleSidebar(!sidebarOpen)}
              activeClass="active"
              className="whiteColor"
              style={{ padding: "10px 15px" }}
              to="aboutus"
              spy={true}
              smooth={true}
              offset={-60}
            >
              About Us
            </Link>
          )}
        </li>
        <li className="semiBold font15 pointer">
          <a href="/partners" onClick={() => toggleSidebar(!sidebarOpen)}>
            <span className="font15 pointer whiteColor" style={{ padding: "10px 15px" }}>
              Our Partners
            </span>
          </a>
        </li>
        <li className="semiBold font15 pointer">
          {isOtherNav ? (
            <>
              <a
                href="/#contact"
                onClick={() => {
                  scroller.scrollTo("contact", {
                    smooth: true,
                    offset: -70,
                    duration: 100,
                  });
                }}
              >
                <span
                  className="font15 pointer whiteColor"
                  style={{ padding: "10px 15px" }}
                >
                  Contact Us
                </span>
              </a>
            </>
          ) : (
            <Link
              onClick={() => toggleSidebar(!sidebarOpen)}
              activeClass="active"
              className="whiteColor"
              style={{ padding: "10px 15px" }}
              to="contact"
              spy={true}
              smooth={true}
              offset={-60}
            >
              Contact
            </Link>
          )}
        </li>
      </UlStyle>
    </Wrapper>
  );
}

const Wrapper = styled.nav`
  width: 400px;
  height: 100vh;
  position: fixed;
  top: 0;
  padding: 0 30px;
  right: ${(props) => (props.sidebarOpen ? "0px" : "-400px")};
  z-index: 9999;
  @media (max-width: 400px) {
    width: 100%;
  }
`;
const SidebarHeader = styled.div`
  padding: 20px 0;
`;
const CloseBtn = styled.button`
  border: 0px;
  outline: none;
  background-color: transparent;
  padding: 10px;
`;
const UlStyle = styled.ul`
  padding: 40px;
  li {
    margin: 20px 0;
  }
`;