import React from "react";
import styled from "styled-components";
// Components
import TopNavbar from "../components/Nav/TopNavbar";
import Footer from "../components/Sections/Footer";
// Assets
import Img1 from "../assets/img/services/4.jpg";
import Img2 from "../assets/img/services/fm-1.jpg";
import Img3 from "../assets/img/services/fm-2.jpg";
import Dots from "../assets/svg/Dots";
import JoinUs from "../components/Sections/JoinUs";

import ProjectBox from "../components/Elements/ProjectBox";
// Assets
import ProjectImg1 from"../assets/img/services/1.jpg";
import ProjectImg2 from "../assets/img/services/2.jpg";
import ProjectImg3 from "../assets/img/services/3.jpg";
import ProjectImg4 from "../assets/img/services/4-1.jpg";

export default function Ss() {
  return (
    <>
      <TopNavbar />
      <Wrapper id="siatraining" className="container flexSpaceCenter" style={{
        paddingTop: "10px",
      }}>
        <div className="container ">
        <h1 className="extraBold">
            Security Services
        </h1>
        <div className="row textCenter">
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <ProjectBox
                img={ProjectImg1}
                title="SIA Training"
                text="Looking for the best SIA training program in town? Look no further! Our comprehensive training courses are designed to prepare contractors and workers for success in the security industry."
                action={() => window.location.href = "/sia-training"}
              />
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <ProjectBox
                img={ProjectImg2}
                title="Security"
                text="Are you interested in a career in security? Do you have a passion for helping others and a commitment to keeping people safe? If so, we invite you to join our network of security providers."
                action={() => window.location.href = "/security-services"}
              />
            </div>
          </div>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <ProjectBox
                img={ProjectImg3}
                title="Cleaners"
                text="Looking for a fulfilling career as a cleaner? Join our team of experienced and dedicated cleaners who play a critical role in maintaining safe and healthy environments. We offer exciting opportunities for individuals who have a passion for providing top-notch cleaning services."
                action={() => window.location.href = "/cleaning-services"}
              />
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <ProjectBox
                img={ProjectImg4}
                title="Fire Marshal"
                text="Looking for a fulfilling career as a fire marshal? Join our team of skilled fire safety professionals and make a difference in keeping people safe from fire hazards. We offer exciting opportunities for individuals who are passionate about fire safety and committed to excellence in the industry."
                action={() => window.location.href = "/firemarshal"}
              />
            </div>
          </div>
          {/* <JoinUs service="Fire Marshal" /> */}
        </div>
      </Wrapper>
      <Footer />
    </>
  );
}

const Wrapper = styled.section`
  padding-top: 80px;
  width: 100%;
  min-height: 840px;
  margin-top: 100px;
  @media (max-width: 960px) {
    flex-direction: column;
    margin-top: 10px;
  }
`;
const LeftSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    margin: 20px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 30px 0 50px 0;
  }
`;
const RightSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin-top: 30px;
  }
`;
const HeaderP = styled.div`
  max-width: 470px;
  padding: 15px 0 50px 0;
  line-height: 1.5rem;
  @media (max-width: 960px) {
    padding: 15px 0 50px 0;
    text-align: center;
    max-width: 100%;
  }
`;
const BtnWrapper = styled.div`
  max-width: 190px;
  @media (max-width: 960px) {
    margin: 0 auto;
  }
`;
const GreyDiv = styled.div`
  width: 30%;
  height: 430px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  @media (max-width: 960px) {
    display: none;
  }
`;
const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 9;
  @media (max-width: 960px) {
    width: 100%;
    justify-content: center;
  }
`;
const Img = styled.img`
  @media (max-width: 960px) {
    width: 90%;
    height: auto;
    padding-bottom: 0;
  }
`;
const QuoteWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 50px;
  max-width: 330px;
  padding: 30px;
  z-index: 99;
  @media (max-width: 960px) {
    left: 20px;
  }
  @media (max-width: 560px) {
    bottom: -50px;
  }
`;
const QuotesWrapper = styled.div`
  position: absolute;
  left: -20px;
  top: -10px;
`;
const DotsWrapper = styled.div`
  position: absolute;
  right: -100px;
  bottom: 100px;
  z-index: 2;
  @media (max-width: 960px) {
    right: 100px;
  }
  @media (max-width: 560px) {
    display: none;
  }
`;
const Form = styled.form`
  padding: 70px 0 30px 0;
  input,
  textarea {
    width: 100%;
    background-color: transparent;
    border: 0px;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #707070;
    height: 30px;
    margin-bottom: 30px;
  }
  textarea {
    min-height: 100px;
  }
  @media (max-width: 860px) {
    padding: 30px 0;
  }
`;
const ButtonInput = styled.input`
  border: 1px solid #14117a;
  background-color: #14117a;
  width: 100%;
  padding: 15px;
  outline: none;
  color: #fff;
  :hover {
    background-color: #0d0b4e;
    border: 1px solid #7620ff;
    color: #fff;
  }
  @media (max-width: 991px) {
    margin: 0 auto;
  }
`;
const ContactImgBox = styled.div`
  max-width: 180px; 
  align-self: flex-end; 
  margin: 10px 30px 10px 0;
`;
const SumbitWrapper = styled.div`
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 50px;
  }
`;
