import {React, useState} from "react";
import styled from "styled-components";
// Assets
import ContactImg from "../../assets/img/contact.jpeg";

export default function Contact() {
  const [formState, setFormState] = useState({});
  const changeHandler = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };
    const submitHandler = (e) => {
    e.preventDefault();
    if(formState.Name === "" || formState.Email === "" || formState.Message === "" || formState.Phone === "" || formState.Sub === ""){
      alert("Please fill out all the fields");
      return;
    }
    console.log(formState);
    const config = {
      SecureToken:"43bc1853-21ce-4a51-8b3d-57c57c8b1f14",
      To:"infobonkur@gmail.com",
      From:"infobonkur@gmail.com",
      Subject:`New Message from ${formState.Name}`,
      Body:`<h2>You have a new message from ${formState.Name}</h2>
      <h3>Name:</h3>
      <p>${formState.Name}</p>
      <h3>Phone Number:</h3>
        <p>${formState.Phone}</p>
      <h3>Email:</h3>
        <p>${formState.Email}</p>
      <h3>Subject:</h3>
        <p>${formState.Sub}</p>
      <h3>Message:</h3>
        <p>${formState.Message}</p>`,
    };
    if(window.Email){
      window.Email.send(config).then(
        alert("Message Sent Successfully")
      )
      .catch((err) => {
        console.log(err)
        alert("Message Failed to Send")
      });
    }
  };


  return (
    <Wrapper id="contact">
      <div className="">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Let's get in touch</h1>
            <p className="font13">
              We are always happy to hear from you. If you have any questions or comments,
              please feel free to contact us.
            </p>
          </HeaderInfo>
          <div className="row" style={{ paddingBottom: "30px" }}>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <Form>
                <label className="font13">Name:</label>
                <input type="text" id="fname" name="Name" className="font20 extraBold" onChange={changeHandler} />
                <label className="font13">Email:</label>
                <input type="text" id="email" name="Email" className="font20 extraBold" onChange={changeHandler}/>
                <label className="font13">Phone number:</label>
                <input type="text" id="phone" name="Phone" className="font20 extraBold" onChange={changeHandler}/>
                <label className="font13">Subject:</label>
                <input type="text" id="subject" name="Sub" className="font20 extraBold" onChange={changeHandler}/>
                <label className="font13">Message:</label>
                <textarea rows="4" cols="50" type="text" id="message" name="Message" className="font20 extraBold" onChange={changeHandler}/>
              </Form>
              <SumbitWrapper className="flex">
                <ButtonInput type="submit" value="Send Message" className="pointer animate radius8" style={{ maxWidth: "220px" }} onClick={submitHandler}/>
              </SumbitWrapper>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 flex flexCenter">
                  <img src={ContactImg} alt="contact" style={{ width: "100%"}} className="radius8" />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  padding: 70px 0 30px 0;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Form = styled.form`
  padding: 70px 0 30px 0;
  input,
  textarea {
    width: 100%;
    background-color: transparent;
    border: 0px;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #707070;
    height: 30px;
    margin-bottom: 30px;
  }
  textarea {
    min-height: 100px;
  }
  @media (max-width: 860px) {
    padding: 30px 0;
  }
`;
const ButtonInput = styled.input`
  border: 1px solid #14117a;
  background-color: #14117a;
  width: 100%;
  padding: 15px;
  outline: none;
  color: #fff;
  :hover {
    background-color: #0d0b4e;
    border: 1px solid #7620ff;
    color: #fff;
  }
  @media (max-width: 991px) {
    margin: 0 auto;
  }
`;
const ContactImgBox = styled.div`
  max-width: 180px; 
  align-self: flex-end; 
  margin: 10px 30px 10px 0;
`;
const SumbitWrapper = styled.div`
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 50px;
  }
`;









